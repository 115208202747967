import React, { Fragment, useContext } from 'react';
import Cookies from 'universal-cookie';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import gql from 'graphql-tag';
import UserContext from './UserContext';

const LOGIN_USER = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password }) {
      token
    }
  }
`;

const Login = props => {
  const user = useContext(UserContext);

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 6 }
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 3 }
  };

  const cookies = new Cookies();

  const [
    loginUser,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(LOGIN_USER, {
    onCompleted: ({ loginUser: { token } }) => {
      cookies.set('user', token, { path: '/' });
      props.refetchUser();
    }
  });

  const handleSubmit = ({ email, password }) => {
    loginUser({ variables: { email, password } });
  };

  if (user) {
    return (
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    );
  }

  return (
    <Fragment>
      <Form
        name="login"
        className="mt-12"
        layout="horizontal"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Email"
          name="email"
          {...formItemLayout}
          rules={[
            {
              required: true,
              message: 'Please input your username!'
            }
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          {...formItemLayout}
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      {mutationLoading && <p>Loading...</p>}
      {mutationError && <p>`Error :{`${mutationError}`} Please try again</p>}
    </Fragment>
  );
};

export default Login;
