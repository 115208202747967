import gameGenres from '../data/genres';

import React from 'react';
import { Select } from 'antd';
import { AppstoreFilled } from '@ant-design/icons';

const { Option } = Select;

const HeaderFilter = props => {
  const handleSortingChange = value => {
    props.setSorting(value);
  };

  const handleGenresFilterChange = value => {
    const genresList = value.map(genreObj => genreObj.value);

    props.setGenres(genresList);
  };

  const filterGenresValue = genresList => {
    return genresList.map(genre => {
      return { value: genre, label: genre, key: genre };
    });
  };

  const toggleDisplayChange = () =>
    props.setDisplaySmallCards(!props.displaySmallCards);

  return (
    <React.Fragment>
      <div
        style={{ background: '#404257' }}
        className="min-h-12 py-2 border-transparent
                text-isabelline flex items-center
                justify-around flex-wrap md:justify-start md:px-5 lg:px-12"
      >
        <div>
          <label className="hidden md:inline-block" htmlFor="sorting">
            Sorted by:
          </label>
          <Select
            id="sorting"
            defaultValue="-rating"
            className="my-2 md:mx-3 text-base md:text-sm"
            placeholder="Sort games"
            style={{ minWidth: 140 }}
            onChange={handleSortingChange}
            value={props.sorting}
          >
            <Option value="-rating">Rating</Option>
            <Option value="-releaseDate">Release date</Option>
            <Option value="-updateDate">Last update</Option>
            <Option value="title">Name</Option>
          </Select>
        </div>
        <Select
          mode="multiple"
          labelInValue
          allowClear
          placeholder="Filter by genre"
          style={{ minWidth: 180, width: 'fit-content' }}
          className="text-base md:text-sm"
          maxTagCount={3}
          onChange={handleGenresFilterChange}
          value={filterGenresValue(props.genres)}
        >
          {gameGenres &&
            gameGenres.map(genre => (
              <Option key={genre} value={genre}>
                {genre}
              </Option>
            ))}
        </Select>
        <div className="md:mx-3">{props.gamesAmount} games found</div>
        <button
          onClick={toggleDisplayChange}
          className="hidden md:block focus:outline-none ml-auto lg:mr-5 hover:text-brandYellow active:text-darkYellow"
        >
          <AppstoreFilled className="text-2xl" />
        </button>
      </div>
    </React.Fragment>
  );
};

export default HeaderFilter;
