import React from 'react';

const FooterContent = () => {
  return (
    <React.Fragment>
      Made with{' '}
      <span role="img" aria-label="hearth">
        ❤️
      </span>{' '}
      by{' '}
      <a
        className="text-grayBlue font-medium"
        href="https://twitter.com/guar47"
        target="_blank"
        rel="noopener noreferrer"
      >
        @guar47
      </a>{' '}
      &{' '}
      <a
        className="text-grayBlue font-medium"
        href="https://twitter.com/osty_me"
        target="_blank"
        rel="noopener noreferrer"
      >
        @osty_me
      </a>
    </React.Fragment>
  );
};

export default FooterContent;
