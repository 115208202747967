import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TwitterOutlined } from '@ant-design/icons';

import logo from '../images/logo100.svg';
import UserContext from './UserContext';

const HeaderMenu = () => {
  const user = useContext(UserContext);

  return (
    <div className="bg-gunmetal border-transparent text-isabelline flex items-center justify-between flex-wrap lg:px-12">
      <Link
        to="/"
        className="px-5 font-logo flex hover:text-isabelline
                   active:text-isabelline text-lg items-center"
      >
        <img className="h-12" src={logo} alt="Arcade Hub Logo" />
        <span className="pl-2">Arcade Hub</span>
      </Link>
      <div className="flex">
        {user && <Link className="px-3 hover:text-brandYellow" to="/new-game">Add game</Link>}
        <Link className="px-3 hover:text-brandYellow" to="/about">
          About
        </Link>
        <a
          className="px-5 hover:text-brandYellow"
          href="https://twitter.com/ArcadeHubTweets"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterOutlined className="text-2xl" />
        </a>
      </div>
    </div>
  );
};

export default HeaderMenu;
