import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import Cookies from 'universal-cookie';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';

import './index.css';
import './tailwind.generated.css';

const cache = new InMemoryCache();
let httpLink = new HttpLink({
  credentials: 'same-origin'
});

if (process.env.NODE_ENV === 'development') {
  httpLink = new HttpLink({
    uri: 'http://localhost:4000/graphql'
  });
}

const authLink = setContext((_, { headers }) => {
  const cookies = new Cookies();
  const token = cookies.get('user');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink)
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);
