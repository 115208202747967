import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { Row, Col, Typography, Rate } from 'antd';
import moment from 'moment';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Helmet } from 'react-helmet';

import appStoreBadge from '../images/app-store-badge.svg';

const { Paragraph } = Typography;

const GET_GAME = gql`
  query Game($id: ID!) {
    game(id: $id) {
      id
      title
      rating
      ratingCount
      appleId
      developerName
      developerURL
      genre
      description
      appStoreURL
      releaseDate
      updateDate
      releaseNotes
      iconURL
      screenshots
    }
  }
`;

const GameDetails = () => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_GAME, { variables: { id } });
  const getDate = dateString => {
    return moment(Number(dateString)).format('DD MMMM YYYY');
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  const toggleLightbox = selectedIndex => {
    setSelectedIndex(selectedIndex);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }
  const { game } = data;
  const screenshots = game.screenshots.map(screenshot => {
    return { src: screenshot };
  });
  const gameRating = game.rating ? game.rating.toFixed(1) : 0;
  const starRating = Math.round(gameRating * 2) / 2;

  return (
    <Fragment>
      <Helmet>
        <meta
          name="description"
          content={`Detailed page of the Apple Arcade game - ${game.title}`}
        />
        <meta name="keywords" content={game.title} />
        <title>Arcade Hub - {game.title}</title>
        <link rel="canonical" href={`https://arcade-hub.com/game/${game.id}`} />
      </Helmet>
      <Row className="px-4 pt-5 bg-white xl:px-10">
        <Col xs={24}>
          <Row>
            <Col xs={8} md={6} lg={4}>
              <img src={game.iconURL} alt="Game icon" />
            </Col>
            <Col className="flex flex-col px-3 md:px-6" xs={16}>
              <span className="text-lg font-bold md:text-2xl">
                {game.title}
              </span>
              <div className="flex flex-wrap items-center">
                <span className="mr-2 text-lg font-bold md:text-2xl">
                  {gameRating}
                </span>
                <Rate
                  className="pb-1"
                  disabled
                  allowHalf
                  defaultValue={starRating}
                />
              </div>
              <div>
                <span className="font-mono opacity-80 text-md">
                  {game.ratingCount ? game.ratingCount : 0} total ratings
                </span>
              </div>
              <a
                href={game.appStoreURL}
                target="_blank"
                rel="noopener noreferrer"
                className="w-3/4 mt-2"
              >
                <img src={appStoreBadge} alt="Apple Store Badge" />
              </a>
            </Col>
          </Row>
        </Col>
        <Col className="mt-5" xs={24}>
          <div className="flex flex-col justify-center text-lg">
            <span>
              Genre: <span className="font-bold">{game.genre}</span>
            </span>
            <span>
              Developer:{' '}
              {game.developerURL ? (
                <a
                  className="font-bold underline"
                  href={game.developerURL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {game.developerName}
                </a>
              ) : (
                game.developerName
              )}{' '}
            </span>
            <span>
              Release Date:{' '}
              <span className="font-bold">{getDate(game.releaseDate)}</span>
            </span>
            <span>
              Last Update:{' '}
              <span className="font-bold">{getDate(game.updateDate)}</span>
            </span>
          </div>
        </Col>
        <Col className="mt-5" xs={24} md={22} lg={20}>
          <span className="text-lg font-bold">Last update release notes: </span>
          <Paragraph
            ellipsis={{
              rows: 10,
              expandable: true
            }}
          >
            {game.releaseNotes &&
              game.releaseNotes.split('\n').map((item, key) => {
                return (
                  <div className="mt-1" key={key}>
                    {item}
                  </div>
                );
              })}
          </Paragraph>
        </Col>
        <Col className="mt-5" xs={24} md={22} lg={20}>
          <span className="text-lg font-bold">About: </span>
          <Paragraph
            ellipsis={{
              rows: 10,
              expandable: true
            }}
          >
            {game.description &&
              game.description.split('\n').map((item, key) => {
                return <div key={key}>{item}</div>;
              })}
          </Paragraph>
        </Col>
        <Col className="my-3" span={24}>
          <span className="text-lg font-bold">Screenshots: </span>
          <div className="flex flex-wrap">
            {screenshots.map(({ src }, j) => (
              <div
                className="w-auto mb-3 cursor-pointer md:mr-3 md:w-56"
                onClick={() => toggleLightbox(j)}
                onKeyPress={() => toggleLightbox(j)}
                role="button"
                tabIndex="0"
                key={j}
              >
                <img alt="Game screenshots" src={src} />
              </div>
            ))}
          </div>
          <ModalGateway>
            {lightboxIsOpen ? (
              <Modal onClose={toggleLightbox} closeOnEsc={false}>
                <Carousel
                  currentIndex={selectedIndex}
                  views={screenshots}
                  styles={{
                    container: base => ({
                      ...base,
                      height: '100vh'
                    }),
                    view: base => ({
                      ...base,
                      alignItems: 'center',
                      display: 'flex ',
                      height: 'calc(100vh - 54px)',
                      justifyContent: 'center',
                      '& > img': {
                        maxHeight: 'calc(100vh - 94px)'
                      }
                    })
                  }}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </Col>
      </Row>
    </Fragment>
  );
};

export default GameDetails;
