import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Form, Input, Button } from 'antd';

const CREATE_GAME = gql`
  mutation CreateGame($appleId: Float!) {
    createGame(input: { appleId: $appleId }) {
      id
    }
  }
`;

const NewGame = () => {
  const [isCreated, setIsCreated] = useState(false);

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 6 }
  };
  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 3 }
  };

  const [
    createGame,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(CREATE_GAME, {
    onCompleted: () => setIsCreated(true)
  });

  const handleFinish = ({ appleId, genre }) => {
    createGame({ variables: { appleId: Number(appleId), genre } });
  };

  if (isCreated) {
    return (
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    );
  }

  return (
    <Fragment>
      <Form className="mt-12" layout="horizontal" onFinish={handleFinish}>
        <Form.Item
          label="Apple ID"
          name="appleId"
          {...formItemLayout}
          rules={[
            {
              required: true,
              message: 'Please input Apple id!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      {mutationLoading && <p>Loading...</p>}
      {mutationError && <p>`Error :{`${mutationError}`} Please try again</p>}
    </Fragment>
  );
};

export default NewGame;
