const gameGenres = [
  'Action',
  'Adventure',
  'Casual',
  'Entertainment',
  'Family',
  'Music',
  'Other',
  'Puzzle',
  'Racing',
  'Role Playing',
  'Simulation',
  'Strategy',
  'Sports',
  'Word'
];

export default gameGenres;
