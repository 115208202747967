import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import MainLayout from './MainLayout';
import UserContext from './UserContext';

const ME = gql`
  {
    me {
      email
      name
      role
    }
  }
`;

const App = () => {
  ReactGA.initialize('UA-161041734-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const meResults = useQuery(ME);
  const [user, setUser] = useState(false);

  useEffect(() => {
    if (!meResults.loading && meResults.data) {
      setUser(meResults.data.me);
    }
  }, [meResults]);

  return (
    <UserContext.Provider value={user}>
      <Helmet>
        <title>Arcade Hub - Apple Arcade games list</title>
        <link rel="canonical" href="https://arcade-hub.com" />
      </Helmet>
      <MainLayout refetchUser={meResults.refetch} />
    </UserContext.Provider>
  );
};

export default App;
