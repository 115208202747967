import React, { useState, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import Games from './Games';
import HeaderFilter from './HeaderFilter';
import GameDetails from './GameDetails';
import Login from './Login';
import NotFound from './NotFound';
import NewGame from './NewGame';
import About from './About';
import UserContext from './UserContext';

const Router = props => {
  const [sorting, setSorting] = useState('-rating');
  const [genres, setGenres] = useState([]);
  const [displaySmallCards, setDisplaySmallCards] = useState(true);
  const [gamesAmount, setGamesAmount] = useState(0);
  const user = useContext(UserContext);

  return (
    <Switch>
      <Route exact path="/">
        <HeaderFilter
          setSorting={setSorting}
          setGenres={setGenres}
          setDisplaySmallCards={setDisplaySmallCards}
          displaySmallCards={displaySmallCards}
          sorting={sorting}
          genres={genres}
          gamesAmount={gamesAmount}
        />
        <Games
          sorting={sorting}
          genres={genres}
          displaySmallCards={displaySmallCards}
          setGamesAmount={setGamesAmount}
        />
      </Route>
      <Route exact path="/game/:id">
        <GameDetails />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
      <Route exact path="/login">
        <Login refetchUser={props.refetchUser} />
      </Route>
      {user.role === 'admin' && (
        <Route exact path="/new-game">
          <NewGame />
        </Route>
      )}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Router;
