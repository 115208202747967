import React, { Fragment } from 'react';
import { Card, Tag } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import gameGenres from '../data/genres';

const colors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
  '#2db7f5',
  '#87d068',
  '#108ee9',
  '#f50'
];

const gameGenresWithColors = gameGenres.map((genre, index) => {
  const color = colors[index] || 'black';

  return { genre, color };
});

function GameCardSmall({ game, shortDate }) {
  const coloredTag = genreName => {
    const genre = gameGenresWithColors.find(({ genre }) => genre === genreName);

    return (
      <Tag style={{ marginRight: 0 }} color={genre ? genre.color : 'black'}>
        {genreName}
      </Tag>
    );
  };
  const gameRating = game.rating ? game.rating.toFixed(1) : 0;

  return (
    <Fragment>
      <Link to={`/game/${game.id}`}>
        <Card
          hoverable
          style={{ minWidth: 160, maxWidth: 350 }}
          className={'mx-auto'}
          cover={<img alt={'game icon'} src={game.iconURL} />}
          bodyStyle={{ padding: 0 }}
        >
          <div
            className="flex flex-col p-6 md:p-3 xl:p-6"
            style={{ minHeight: '193px' }}
          >
            <p className="font-mono text-xs opacity-50">
              {shortDate(game.releaseDate)}
            </p>
            <h1 className="text-base font-logo">{game.title}</h1>
            <p className="mb-3 text-xs opacity-50">{game.developerName}</p>
            <div className="flex flex-wrap items-center justify-between mt-auto">
              <div className="text-base">
                <span className="font-bold">{gameRating}</span>
                <StarFilled
                  className="ml-1 align-text-bottom"
                  style={{ color: '#f1bc19' }}
                />
              </div>
              <span className="self-center">{coloredTag(game.genre)}</span>
            </div>
            <span className="mt-1 opacity-50 text-xs font-mono">
              {game.ratingCount ? game.ratingCount : 0} total ratings
            </span>
          </div>
        </Card>
      </Link>
    </Fragment>
  );
}

export default GameCardSmall;
